import { RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const NavItem = styled.div`
    ${RowFlex}
    cursor: pointer;
    gap: 1rem;
`
export const Title = styled.span`
    font-size: 1rem;
    color: ${({ active }) => (active ? '#FFFFFF' : '#888888')};
`
export const SubscriptionEntryPoint = styled.a`
    height: 28px;
    padding: 8px 14px;
    border-radius: 36px;
    gap: 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #611473;
    height: 36px;
    color: white;

    font-size: 12px;
    font-weight: 600;
    line-height: 17.5px;
    text-wrap: nowrap;

    img {
        width: 20px;
        height: 20px;
    }
`

export const LoginWrapper = styled.div`
    display: flex;
    gap: 8px;
`
