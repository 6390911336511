import { analytics } from '@/utils/firebase'
import dayjs from 'dayjs'
import { logEvent } from 'firebase/analytics'
import posthog from 'posthog-js'
import { isMobile, browserName, osName } from 'react-device-detect'

export const EventTracking = (eventName, eventParams, session) => {
    const defaultParams = {
        platform: isMobile ? 'mweb' : 'desktop',
        browser: browserName,
        os: osName,
        timestamp: Number(dayjs().format('x')),
    }
    if (session?.userInfo?.id) {
        defaultParams.userId = session?.userInfo?.id
    } else {
        defaultParams.userId = session?.guestUserId
    }

    logEvent(analytics, eventName, { ...defaultParams, ...eventParams })

    // posthog
    if (eventParams.trackOnPostHog) {
        posthog.capture(eventName, {
            userId: session?.userInfo?.id,
            ...eventParams,
        })
    }
}
