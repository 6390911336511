// export const APPSFLYER_ONELINK_WEB_TO_APP_URL =
//     'https://listen.noice.id/cyOg/t3auukl5'

export const APPSFLYER_ONELINK_WEB_TO_APP_URL =
    'https://noiceid.onelink.me/cyOg/xw8hfyxp'

export const APPSFLYER_ONELINK_WEB_TO_APP_BUKA_URL =
    'https://noiceid.onelink.me/cyOg/kumtoiwa'

export const imageCDN = [
    // 'storage.googleapis.com',
    'images.noiceid.cc',
    'storage.googleapis.com/noice-images/',
    'storage.googleapis.com/noice-misc/',
]
